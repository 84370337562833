import React from 'react'
import dateFormat from 'dateformat'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import useMatchData from 'src/common/hooks/use-match-data'
import { getClubInfo } from 'src/common/clubs'

type ExpectedReturnEditProps = { value?: string; onChange: any }
const ExpectedReturnEdit = ({ value, onChange }: ExpectedReturnEditProps) => {
  const matchData = useMatchData()

  const items = matchData
    .filter((match) => new Date(match.date) > new Date())
    .map((match) => {
      const date = new Date(match.date)
      const dateStr = dateFormat(date, 'm/d')
      return (
        <MenuItem key={dateStr} value={match.date}>
          {getClubInfo(match.homeTeam).abbrev} vs{' '}
          {getClubInfo(match.awayTeam).abbrev}
          <br />
          {dateStr}
        </MenuItem>
      )
    })

  return (
    <Select
      style={{ width: '100%' }}
      value={value ?? ''}
      onChange={(e, newValue) => {
        onChange(e.target.value)
      }}
    >
      <MenuItem value="">unknown</MenuItem>
      {items}
    </Select>
  )
}

export default ExpectedReturnEdit
