import { useStaticQuery, graphql } from 'gatsby'
import { UnavailableDataQuery } from '../../../types/graphql-types'
import { InjuredPlayer } from 'plugins/gatsby-source-scrape-transfermarkt-injuries/scrape-injuries'

export type Injury = InjuredPlayer & { id: string }

//? Still not sure how to get the 'real' types how I want
// type UnavailablePlayer = Pick<
//   Injured,
//   'id' | 'date' | 'reason' | 'expectedReturn'
// >

const unavailableCategories = [
  'injured',
  'questionable',
  'suspended',
  'other',
] as const
export type UnavailablePlayer = {
  date: string
  reason: string
  name: string
  expectedReturn: string | null
  severity: 0 | 1 | 2 | 3
  category: typeof unavailableCategories[number]
}

export type Unavailable = {
  injured: UnavailablePlayer[]
  questionable: UnavailablePlayer[]
  suspended: UnavailablePlayer[]
  other: UnavailablePlayer[]
}

const useInjuryData = () => {
  const query = useStaticQuery<UnavailableDataQuery>(graphql`
    query UnavailableData {
      allUnavailable(sort: { fields: severity, order: DESC }) {
        nodes {
          category
          date
          expectedReturn
          name
          reason
          severity
        }
      }
    }
  `)

  //? the typing returned from graphql-types is too hard to make correct
  const nodes = query.allUnavailable.nodes as UnavailablePlayer[]

  const injured = nodes.filter((node) => node.category === 'injured')
  const questionable = nodes.filter((node) => node.category === 'questionable')
  const suspended = nodes.filter((node) => node.category === 'suspended')
  const other = nodes.filter((node) => node.category === 'other')

  const unavailabeData: Unavailable = {
    injured,
    questionable,
    suspended,
    other,
  }

  return unavailabeData
}

export default useInjuryData
