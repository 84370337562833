import { useStaticQuery, graphql } from 'gatsby'
import { SadJosefsQuery } from 'types/graphql-types'

/*
  TODO:
  ---
    * Premature abstraction turned out to be foolish
      * Make this return something more broadly usable
        * (I think it still makes sense to have a hook)
 */

//these should correspond to the appropriate filenames, obvs
export type SadJosefOptions = 'Sad'

const useJosef = (whichJosef?: SadJosefOptions) => {
  const { allFile } = useStaticQuery<SadJosefsQuery>(graphql`
    query SadJosefs {
      allFile(
        filter: {
          relativeDirectory: { eq: "sad-josefs" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100, grayscale: true) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  //!bad abstraction is bad
  const josefIndex = whichJosef
    ? allFile.nodes.findIndex(
        (josef) =>
          josef.childImageSharp?.fluid?.originalName === `${whichJosef}.png`
      )
    : 0

  const josefs: SadJosefOptions[] = []
  allFile.nodes.forEach((node) =>
    josefs.push(
      node.childImageSharp?.fluid?.originalName?.replace(
        '.png',
        ''
      ) as SadJosefOptions
    )
  )
  josefs.sort()

  return {
    josefFluidData: allFile.nodes[josefIndex].childImageSharp?.fluid,
    josefs,
  }
}

export default useJosef
