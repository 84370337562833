/* eslint-disable react/display-name */
import { BoolSetState } from '../settings/settings-context'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import MaterialTable, { Column } from 'material-table'
import {
  FSUnavailablePlayer,
  UnavailablePlayerSetState,
  DeletedPlayersSetState,
  unavailableCategories,
} from './use-firestore-unavailable-data'
import TableIcons from './table-icons'
import PlayerNameEdit from './player-name-edit'
import ExpectedReturnEdit from './expected-return-edit'

type CategoryLookup = {
  [K in typeof unavailableCategories[number]]: typeof unavailableCategories[number]
}
type EditTableProps = {
  isLoading: boolean
  unavailablePlayers: FSUnavailablePlayer[] | undefined
  setUnavailablePlayers: UnavailablePlayerSetState
  setDeletedPlayers: DeletedPlayersSetState
  setHasEdited: BoolSetState
}

const EditTable = ({
  isLoading,
  unavailablePlayers,
  setUnavailablePlayers,
  setDeletedPlayers,
  setHasEdited,
}: EditTableProps) => {
  const categoryLookup: CategoryLookup = {
    injured: 'injured',
    questionable: 'questionable',
    suspended: 'suspended',
    other: 'other',
  }

  const columns: Column<FSUnavailablePlayer>[] = [
    {
      title: 'Name',
      field: 'name',
      editComponent: (props) => (
        <PlayerNameEdit value={props.value} onChange={props.onChange} />
      ),
    },
    { title: 'Reason', field: 'reason' },
    {
      title: 'Category',
      field: 'category',
      lookup: categoryLookup,
      initialEditValue: 'injured',
      defaultSort: 'asc',
    },
    { title: 'Date', field: 'date', type: 'datetime' },
    {
      title: 'Return',
      field: 'expectedReturn',
      type: 'datetime',
      emptyValue: 'unknown',
      editComponent: (props) => (
        <ExpectedReturnEdit value={props.value} onChange={props.onChange} />
      ),
    },
    {
      title: 'Severity',
      field: 'severity',
      lookup: { 0: 'ok', 1: 'low', 2: 'medium', 3: 'high' },
      initialEditValue: 2,
    },
  ]

  const data = unavailablePlayers?.map((player) => ({
    ...player,
  }))

  return (
    <>
      <DatePickerStyleOverride />
      <TableWrapper>
        <MaterialTable
          data={data ?? []}
          isLoading={isLoading}
          columns={columns}
          title="Edit Unavailable Players"
          //@ts-ignore material-table seems to have their own typing wrong?
          icons={TableIcons}
          options={{
            search: false,
            paginationType: 'stepped',
            actionsColumnIndex: -1,
            paging: false,
            draggable: false,
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                if (data) {
                  const updatedData = [...data]

                  //Just overwrite existing player row if a duplicate is added.
                  //Lazy, but it works...
                  const index = data.findIndex(
                    (player) => player.name === newData?.name
                  )
                  if (index >= 0) {
                    updatedData[index] = newData
                  } else {
                    updatedData.push(newData)
                  }

                  const isValidated = checkRequired(newData)

                  if (isValidated) {
                    setHasEdited(true)
                    fixTypes(updatedData)
                    setUnavailablePlayers(updatedData)
                  } else {
                    reject()
                  }
                }
                resolve()
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (data) {
                  const updatedData = [...data]
                  const oldIndex = data.findIndex(
                    (player) => player.name === oldData?.name
                  )
                  const index = data.findIndex(
                    (player) => player.name === newData?.name
                  )

                  if (index >= 0) {
                    updatedData[index] = newData
                  } else {
                    alert("tried to update player but couldn't find name")
                    reject()
                  }

                  //delete old entry if new one becomes a duplicate
                  if (oldIndex !== index) {
                    updatedData.splice(oldIndex, 1)
                  }

                  const isValidated = checkRequired(newData)
                  if (isValidated) {
                    setHasEdited(true)
                    fixTypes(updatedData)
                    setUnavailablePlayers(updatedData)
                  } else {
                    reject()
                  }
                }
                resolve()
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                if (data) {
                  const updatedData = [...data]
                  const index = updatedData.findIndex(
                    (player) => player.name === oldData.name
                  )
                  index >= 0
                    ? updatedData.splice(index, 1)
                    : reject("tried to delete player but couldn't find name")
                  setHasEdited(true)
                  fixTypes(updatedData)
                  setUnavailablePlayers(updatedData)
                  setDeletedPlayers((playerNames) =>
                    playerNames
                      ? [...playerNames, oldData.name]
                      : [oldData.name]
                  )
                }
                resolve()
              }),
          }}
        />
      </TableWrapper>
    </>
  )
}

export default React.memo(EditTable)

const DatePickerStyleOverride = createGlobalStyle`
  .MuiPickersModal-dialog * {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }
`

const TableWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing(2)}px;
  tr:last-child {
    border-bottom: none !important;
    td {
      border: none;
    }
  }
  tfoot {
    td {
      border: none;
    }
  }
`

/**
 * Function to fix material-table's casting all data to strings
 *
 * @param updatedData Copied data that will be mutated
 */
const fixTypes = (updatedData: FSUnavailablePlayer[]) => {
  updatedData.forEach((datum) => {
    datum.date = new Date(datum.date)
    //@ts-ignore since this is actually a string by now
    datum.severity = parseInt(datum.severity)
    datum.expectedReturn = datum.expectedReturn
      ? new Date(datum.expectedReturn)
      : null
  })
}

/**
 * Check required fields just for player and not all data
 *
 * @param player Data just for that player's row
 */
const checkRequired = (player: FSUnavailablePlayer) => {
  const { category, date, name, reason, severity } = player
  //all but expectedReturn are required
  if (category && date && name && reason && severity) {
    return true
  } else {
    window.alert('Every field except "Return" is required')
    return false
  }
}
