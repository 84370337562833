import React, { useState } from 'react'
import styled from 'styled-components'
// import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
// import List from '@material-ui/core/List'
// import ListItem from '@material-ui/core/ListItem'
// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Typography from '@material-ui/core/Typography'
import PlayerItem from './player-item'
import { UnavailablePlayer } from './use-injury-data'
import { spacing } from 'src/common/styles'

type InjuriesListProps = {
  unavailablePlayers: UnavailablePlayer[]
  title: string
}
const PlayerList = ({ unavailablePlayers, title }: InjuriesListProps) => {
  const defaultExpanded = unavailablePlayers.map((player) => false)
  const [expandedPanels, setExpandedPanels] = useState<boolean[]>(
    defaultExpanded
  )
  const isAnyPanelExpanded = expandedPanels.includes(true)

  const toggleExpandedPanel = (i: number) => () => {
    setExpandedPanels((expandedPanels) => {
      const updatedExpandedPanels = [...expandedPanels]
      updatedExpandedPanels[i] = !expandedPanels[i]
      return updatedExpandedPanels
    })
  }

  const toggleAllExpandedPanels = () => {
    setExpandedPanels(expandedPanels.map(() => !isAnyPanelExpanded))
  }

  const players = unavailablePlayers.map((injury, i) => (
    <PlayerItem
      key={injury.name}
      i={i}
      player={injury}
      isExpanded={expandedPanels[i]}
      toggleExpandedPanel={toggleExpandedPanel}
    />
  ))

  return (
    <>
      {unavailablePlayers.length > 0 && (
        <Container>
          <TopSection>
            <Title variant="h5" component="h2">
              {title}
            </Title>
            <ExpandButton variant="text" onClick={toggleAllExpandedPanels}>
              {isAnyPanelExpanded ? 'collapse' : 'expand'} all
            </ExpandButton>
          </TopSection>
          {/* div is necessary for expand panel styling */}
          <div>{players}</div>
        </Container>
      )}
    </>
  )
}

export default React.memo(PlayerList)

const Container = styled.div`
  width: 100%;
  /* padding: ${(p) => p.theme.spacing(1)}px; */
  @media (min-width: 375px) {
    padding: ${spacing(1)}
  }
  ${(p) => p.theme.breakpoints.up('sm')} {
    max-width: 350px;
  }
`
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`
//workaround to support MUI's component prop
type TitleProps = {
  component: string
}
const Title = styled(Typography)<TitleProps>`
  font-family: 'Roboto Mono', monospace;
  display: inline-block;
`

const ExpandButton = styled(Button)`
  font-family: 'Roboto Mono', monospace;
  font-size: 0.9em;
`
