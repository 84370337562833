import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

export default {
  Add: Add,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  SortArrow: ArrowDownward,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn,
}

//// Add?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Check?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Clear?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Delete?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// DetailPanel?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Edit?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Export?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Filter?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// FirstPage?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// SortArrow?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// LastPage?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// NextPage?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// PreviousPage?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// ResetSearch?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// Search?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// ThirdStateCheck?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
//// ViewColumn?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
