import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Img from 'gatsby-image'
import useSadJosef from './use-sad-josef'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

export const SadJosef = () => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'none' }
  )
  const { josefFluidData } = useSadJosef('Sad')

  const sizes = `100vmin`

  return (
    <ElTristeRey {...props}>
      <Img
        draggable={false}
        fadeIn={false}
        //♫ graphql/Img types are not my friend ♫
        fluid={{ ...(josefFluidData as any), sizes }}
      />
    </ElTristeRey>
  )
}

const ElTristeRey = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vmin;
  filter: grayscale(1) opacity(0.6) contrast(1.3) brightness(0.8);
  z-index: -1;
`
