import React, { useState, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'

type Data = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

export const unavailableCategories = [
  'injured',
  'questionable',
  'suspended',
  'other',
] as const
export type FSUnavailablePlayer = {
  name: string
  category: typeof unavailableCategories
  date: Date
  severity: 0 | 1 | 2 | 3
  reason: string
  expectedReturn: Date | null
  tableData?: any
}
export type UnavailablePlayerSetState = React.Dispatch<
  React.SetStateAction<FSUnavailablePlayer[] | undefined>
>
export type DeletedPlayersSetState = React.Dispatch<
  React.SetStateAction<string[] | undefined>
>

const useFirestoreUnavailableData = () => {
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)
  const [deletedPlayers, setDeletedPlayers] = useState<string[]>()
  const [unavailablePlayers, setUnavailablePlayers] = useState<
    FSUnavailablePlayer[]
  >()

  const firestore = firebase.firestore()

  const initState = (players: FSUnavailablePlayer[]) => {
    setUnavailablePlayers(players)
    setDeletedPlayers(undefined)
  }

  const setStateFromFirestore = (data: Data) => {
    const source = data.metadata.hasPendingWrites ? 'Local' : 'Server'
    if (source === 'Local') return

    const players: FSUnavailablePlayer[] | undefined = data.docs.map(
      (playerDoc) => {
        const data = playerDoc.data()
        const date = data.date && new Date(data.date.seconds * 1000)
        const expectedReturn =
          data.expectedReturn && new Date(data.expectedReturn.seconds * 1000)

        return {
          name: playerDoc.id,
          category: data.category,
          date,
          severity: data.severity,
          reason: data.reason,
          expectedReturn,
        }
      }
    )

    players && initState(players)
  }

  useEffect(() => {
    const unsubscribe = firestore
      .collection(collectionStr)
      .onSnapshot(setStateFromFirestore, (err) =>
        setIsPermissionDenied(
          err.message.includes('Missing or insufficient permissions')
        )
      )

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = async () => {
    const batch = firestore.batch()
    if (deletedPlayers) {
      deletedPlayers?.forEach((name) => {
        batch.delete(firestore.doc(docStr(name)))
      })
    }
    unavailablePlayers?.forEach((oldData) => {
      const newData = { ...oldData }
      //'tableData' property seems to get set by material-table
      delete newData.tableData
      Object.keys(newData).forEach(
        //TODO: fix typing
        //@ts-ignore
        (key) => newData[key] === undefined && delete newData[key]
      )

      const doc = firestore.doc(docStr(oldData.name))
      batch.set(doc, newData)
    })

    await batch.commit()
  }

  const revertData = async () => {
    const col = firestore.collection(collectionStr)
    const data = await col.get()
    setStateFromFirestore(data)
  }

  return {
    unavailablePlayers,
    setUnavailablePlayers,
    setDeletedPlayers,
    updateData,
    revertData,
    isPermissionDenied,
  }
}

export default useFirestoreUnavailableData

const collectionStr = 'unavailable'
const docStr = (name: string) => `unavailable/${name}`
