import { useStaticQuery, graphql } from 'gatsby'
import { HeadshotsQuery } from 'types/graphql-types'

/*
  TODO:
  ---
    * Premature abstraction turned out to be foolish
      * Make this return something more broadly usable
        * (I think it still makes sense to have a hook)
 */

//these should correspond to the appropriate filenames, obvs
export type SadJosefOptions = 'Sad'

const useHeadshots = (whichJosef?: SadJosefOptions) => {
  const { allFile } = useStaticQuery<HeadshotsQuery>(graphql`
    query Headshots {
      allFile(
        filter: {
          relativeDirectory: { eq: "headshots/standard" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 140, quality: 75) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  // //!bad abstraction is bad
  // const josefIndex = whichJosef
  //   ? allFile.nodes.findIndex(
  //       (josef) =>
  //         josef.childImageSharp?.fluid?.originalName === `${whichJosef}.png`
  //     )
  //   : 0

  // const josefs: SadJosefOptions[] = []
  // allFile.nodes.forEach((node) =>
  //   josefs.push(
  //     node.childImageSharp?.fluid?.originalName?.replace(
  //       '.png',
  //       ''
  //     ) as SadJosefOptions
  //   )
  // )
  // josefs.sort()

  type Headshots = {
    [key: string]: any
  }
  const headshots: Headshots = {}

  allFile.nodes.forEach((node) => {
    const fluidData = node.childImageSharp?.fluid
    headshots[fluidData?.originalName?.replace('.png', '') ?? 0] = fluidData
  })

  return headshots
}

export default useHeadshots
