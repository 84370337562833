import React from 'react'
import firebase from 'gatsby-plugin-firebase'
import Button from '@material-ui/core/Button'

const SignOutButton = () => {
  const handleSignout = () => {
    firebase.auth().signOut()
  }

  return (
    <Button variant="outlined" color="primary" onClick={handleSignout}>
      Sign Out
    </Button>
  )
}

export default SignOutButton
