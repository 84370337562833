import React, { useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import TimeAgo from 'react-timeago'
import dateFormat from 'dateformat'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ExpansionPanel from '@material-ui/core/Accordion'
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { UnavailablePlayer } from './use-injury-data'
import useHeadshots from 'src/common/hooks/use-headshots'
import { SettingsContext } from '../settings/settings-context'
import useNextMatchData from 'src/common/hooks/use-next-match-data'

/*
  TODO:
  ---
   * calculate missed matches? Or no, since player may still come in?
   * Think about this...
*/

type InjuryItemProps = {
  player: UnavailablePlayer
  isExpanded: boolean
  i: number
  toggleExpandedPanel(i: number): () => void
}
const PlayerItem = ({
  player,
  i,
  isExpanded,
  toggleExpandedPanel,
}: InjuryItemProps) => {
  const { matchDate } = useNextMatchData()
  const [{ isEuroMode }] = useContext(SettingsContext)
  const headshots = useHeadshots()
  const { date, expectedReturn, reason, severity, name } = player
  const dateObj = new Date(date)
  const dateFormatStr = isEuroMode ? 'd/m/yy' : 'm/d/yy'

  const formattedDate = dateFormat(dateObj, dateFormatStr)
  // const formattedReturn =
  //   name === 'Atlanta Supporters' && expectedReturn != null
  //     ? dateFormat(new Date(matchDate ?? expectedReturn), dateFormatStr)
  //     : expectedReturn != null
  //     ? dateFormat(new Date(expectedReturn), dateFormatStr)
  //     : 'unknown'
  const formattedReturn =
    expectedReturn != null
      ? dateFormat(new Date(matchDate ?? expectedReturn), dateFormatStr)
      : 'unknown'

  return (
    <Panel expanded={isExpanded} onChange={toggleExpandedPanel(i)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <AvatarContainer>
          <HeadshotAvatar severity={severity}>
            <Img fluid={headshots[name] as any} />
          </HeadshotAvatar>
        </AvatarContainer>
        <div>
          <Name severity={severity}>
            {name === 'Gonzalo "Pity" Martínez' ? 'Pity Martínez' : name}
          </Name>
          <Reason>{reason}</Reason>
          <TimeAgoText>
            <TimeAgo date={dateObj} />
          </TimeAgoText>
        </div>
      </ExpansionPanelSummary>
      <PanelDetails onClick={toggleExpandedPanel(i)}>
        <DetailsTable size="small" className="mono-font">
          <TableBody>
            <TableRow>
              <TableCell>Injury Date</TableCell>
              <TableCell>{formattedDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Expected Return</TableCell>
              <TableCell>{formattedReturn}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Severity</TableCell>
              <TableCell>
                <Severity severity={severity}>
                  {severityText[severity]}
                </Severity>
              </TableCell>
            </TableRow>
          </TableBody>
        </DetailsTable>
      </PanelDetails>
    </Panel>
  )
}

export default React.memo(PlayerItem)

const Panel = styled(ExpansionPanel)`
  background-color: ${(p) => p.theme.palette.background.paper}cc;
`
const PanelDetails = styled(ExpansionPanelDetails)`
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  cursor: pointer;
`
const DetailsTable = styled(Table)`
  td {
    font-family: 'Roboto Mono', monospace;
  }
  td:last-child {
    /*8ch is based on max date length*/
    width: ${(p) => `calc(8ch + ${p.theme.spacing(4)}px)`};
    /* text-align: center; */
    text-align: right;
  }
  tr:last-child {
    td {
      border: none;
    }
  }
`
export const severityText = ['ok', 'low', 'medium', 'high'] as const
export const severityColor = ['success', 'info', 'warning', 'error'] as const
type SeverityProp = {
  severity: 0 | 1 | 2 | 3
}
const Name = styled.div<SeverityProp>`
  font-size: 1.2em;
  font-weight: bold;
  color: ${(p) => p.theme.palette[severityColor[p.severity]].light};
`
const Reason = styled.div`
  font-weight: normal;
  opacity: 0.85;
  /* color: ${(p) => p.theme.palette.error.main}; */
`
const Severity = styled.span<SeverityProp>`
  color: ${(p) => p.theme.palette[severityColor[p.severity]].light};
`
const TimeAgoText = styled.div`
  font-weight: normal;
  font-size: 0.8em;
  letter-spacing: 0.1ch;
  opacity: 0.8;
  /* color: ${(p) => p.theme.palette.error.dark}; */
`
const AvatarContainer = styled(ListItemAvatar)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const HeadshotAvatar = styled.div<SeverityProp>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(p) => p.theme.palette[severityColor[p.severity]].main};
  /* border: 2px solid ${(p) =>
    p.theme.palette[severityColor[p.severity]].main}; */
`
