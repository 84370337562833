import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import firebase from 'gatsby-plugin-firebase'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import EditTable from './edit-table'
import useFirestoreUnavailableData from './use-firestore-unavailable-data'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import SignOutButton from '../firebase/signout-button'

const MainEditView = () => {
  const {
    unavailablePlayers,
    setUnavailablePlayers,
    setDeletedPlayers,
    updateData,
    revertData,
    isPermissionDenied,
  } = useFirestoreUnavailableData()
  const [hasEdited, setHasEdited] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const props = useMotionNavOffsetStyle()

  useEffect(() => {
    unavailablePlayers && setIsLoading(false)
  }, [unavailablePlayers])

  const functions = firebase.app().functions('us-east1')
  // //enable for local functions development
  // if (process.env.NODE_ENV === 'development') {
  //   functions.useFunctionsEmulator('http://localhost:5001')
  // }

  const handleRevert = () => {
    const shouldRevert = window.confirm(
      "Undo all unpublished changes you've made?"
    )
    if (shouldRevert) {
      revertData()
        .then(() => {
          setHasEdited(false)
        })
        .catch((err) => {
          window.alert(`error reverting data: ${err}`)
          console.error(err)
        })
    }
  }

  const handlePublish = async () => {
    //? material-table doesn't provide any way to have a callback for clicking 'save',
    //? and doesn't expose any kind of 'isEditing' state, so there's no way to be 100%
    //? sure that a row is unsaved, but this is too unimportant of a feature to not just
    //? stick with material-table and live with it.
    const shouldPublish = window.confirm(
      "Are you 100% sure you've saved all rows and you want to rebuild the app using this new information?"
    )
    if (shouldPublish) {
      setIsLoading(true)
      try {
        await updateData()
      } catch (err) {
        console.error(`error updating data: ${err}`)
        window.alert(`error updating data: ${err}`)
        return
      }
      try {
        await functions.httpsCallable('triggerBuild')()
      } catch (err) {
        console.error(`database updated, but error triggering build: ${err}`)
        window.alert(`database updated, but error triggering build: ${err}`)
        return
      }
      setHasEdited(false)
      setIsLoading(false)
    }
  }

  if (!isPermissionDenied) {
    return (
      <motion.div {...props}>
        <Container maxWidth="lg">
          <EditTable
            isLoading={isLoading}
            unavailablePlayers={unavailablePlayers}
            setUnavailablePlayers={setUnavailablePlayers}
            setDeletedPlayers={setDeletedPlayers}
            setHasEdited={setHasEdited}
          />
          <ButtonContainer>
            {/* providing empty string when buttons aren't disabled to hide tooltip */}
            <SignOutButton />
            <Tooltip
              title={hasEdited ? '' : "You haven't changed anything yet"}
              placement="top-end"
            >
              <div style={{ display: 'inline-block' }}>
                <StyledButton
                  onClick={handleRevert}
                  variant="outlined"
                  color="default"
                  disabled={!hasEdited}
                >
                  revert
                </StyledButton>
                <StyledButton
                  onClick={handlePublish}
                  variant="contained"
                  color="primary"
                  disabled={!hasEdited}
                >
                  publish
                </StyledButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        </Container>
      </motion.div>
    )
  }

  if (isPermissionDenied) {
    return (
      <motion.div {...props}>
        <div
          style={{
            textAlign: 'center',
            maxWidth: '800px',
            margin: 'auto',
            padding: '8px',
          }}
        >
          <h2>Sorry, you don't have permission to edit the injuries page.</h2>
          <h3>If you think you're supposed to, contact me.</h3>
          <h4>Or, you can try signing in a different way.</h4>
          <h6>But if you don't think you're supposed to, why are you here?</h6>
          <SignOutButton />
        </div>
      </motion.div>
    )
  }

  return null
}

export default MainEditView

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.spacing(1)}px;
`
const StyledButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing(1)}px;
`
