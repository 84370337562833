import React, { useEffect, useContext } from 'react'
import { Router, RouteComponentProps } from '@reach/router'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import { SadJosef } from 'src/features/injured-players/sad-josef'
import SEO from 'src/common/seo'
import InjuredPlayers from 'src/features/injured-players'
import YoutubeAudioButton from 'src/features/youtube-audio-button'
import EditInjuredPlayers from 'src/features/edit-injured-players'

const Injuries = ({ location }: PageProps) => {
  const { setLocation } = useContext(LayoutContext)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Router basepath="/injuries">
        <EditInjuredPlayers path="/edit" />
        <PageContent path="/" />
      </Router>
    </>
  )
}

export default Injuries

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PageContent = (__: RouteComponentProps) => (
  <>
    <SEO title="Injuries" />
    <InjuredPlayers />
    <SadJosef />
    <YoutubeAudioButton videoId="hW93CV6m-JU" />
  </>
)
