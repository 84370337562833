import { useStaticQuery, graphql } from 'gatsby'
import { RosterDataQuery } from '../../../types/graphql-types'
import { ScrapedPlayer } from 'plugins/gatsby-source-scrape-roster/src/scrape-roster'

export type Player = ScrapedPlayer & { id: string }

const useRosterData = () => {
  const query = useStaticQuery<RosterDataQuery>(graphql`
    query RosterData {
      allRoster(sort: { order: ASC, fields: name }) {
        nodes {
          designations
          headshotUrl
          height
          hometown
          name
          number
          position
          weight
          age
          id
        }
      }
    }
  `)

  //not sure how I feel about the typing, but oh well
  const rosterData = query.allRoster.nodes as Player[]

  return rosterData
}

export default useRosterData
