import { RouteComponentProps } from '@reach/router'
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import firebase from 'gatsby-plugin-firebase'
import FirebaseSignin from '../firebase/firebase-signin'
import MainEditView from './main-edit-view'
import LoadingSpinner from 'src/common/components/loading-spinner'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditInjuredPlayers = (__: RouteComponentProps) => {
  const props = useMotionNavOffsetStyle()
  const [authState, setAuthState] = useState({
    isSignedIn: firebase.auth().currentUser != null,
    isAuthLoaded: false,
  })

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      setAuthState({
        isSignedIn: firebase.auth().currentUser != null,
        isAuthLoaded: true,
      })
    })

    return unsubscribe
  }, [])

  if (authState.isSignedIn) {
    return <MainEditView />
  }

  if (!authState.isSignedIn && authState.isAuthLoaded) {
    return (
      <motion.div {...props}>
        <h2 style={{ textAlign: 'center' }}>
          Sign in to edit the injuries page
        </h2>
        <FirebaseSignin />
      </motion.div>
    )
  }

  return <LoadingSpinner isFullPage />
}

export default EditInjuredPlayers
