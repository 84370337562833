import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import useInjuries from 'src/features/injured-players/use-injury-data'
import PlayerList from 'src/features/injured-players/player-list'

const InjuredPlayers = () => {
  const youtubeButtonHeight = '88px'
  const props = useMotionNavOffsetStyle(
    {},
    {
      cssProp: 'padding',
      addHeightInCssUnits: youtubeButtonHeight,
    }
  )
  const { injured, questionable, suspended, other } = useInjuries()

  return (
    <Container {...props}>
      <PlayerList title="Injured" unavailablePlayers={injured} />
      <PlayerList title="Questionable" unavailablePlayers={questionable} />
      <PlayerList title="Suspended" unavailablePlayers={suspended} />
      <PlayerList title="Other" unavailablePlayers={other} />
    </Container>
  )
}

export default InjuredPlayers

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  padding: ${(p) => p.theme.spacing(1)}px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    max-height: 100vh;
    align-content: flex-start;
  }
`
