import React from 'react'
import useRosterData from 'src/common/hooks/use-roster-data'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

type PlayerNameEditProps = { value?: string; onChange: any }
const PlayerNameEdit = ({ value, onChange }: PlayerNameEditProps) => {
  const rosterData = useRosterData()
  const playerNames = rosterData.map((player) => player.name)

  return (
    <Autocomplete
      value={value ?? null}
      onChange={(event: any, newValue: any) => {
        onChange(newValue)
      }}
      id="player-name"
      options={playerNames}
      autoComplete
      autoHighlight
      blurOnSelect
      openOnFocus
      style={{ minWidth: 225 }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Player name"
          // variant="filled"
        />
      )}
    />
  )
}

export default PlayerNameEdit
